<template>
  <Modal :value="visible"
         :title="title"
         width="740"
         footer-hide
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="equipment">
      <ul class="equipment-cont"
          v-if="item&&item.binds">
        <li>
          <Icon custom="i-icon icon-jingshi"
                size="18"
                color="#F68040" />
          <span>{{item.binds.length > 0 ? "已绑设备" : "未绑定设备"}}</span>
        </li>
        <li class="binding"
            v-for="(item,index) in item.binds"
            :key="index">
          <span>{{item.equiptypename}}</span>
          <Icon custom="i-icon icon-guanbi"
                size="16"
                color="black"
                class="binding-icon"
                @click.stop="onClickUnbind(item)" />
        </li>
      </ul>
      <div class="equipment-filtrate">
        <Select v-model="type"
                placeholder="设备类型"
                @on-change="onChangeType"
                class="m-r-5">
          <Option v-for="item in typeArr"
                  :value="item.id"
                  :key="item.id">{{ item.name }}</Option>
        </Select>
        <Select v-model="model"
                placeholder="设备型号"
                :disabled="!type"
                @on-change="getList"
                class="m-r-5">
          <Option v-for="item in modelArr"
                  :value="item.id"
                  :key="item.id">{{ item.name }}</Option>
        </Select>
        <Input v-model="number"
               placeholder="设备编号"
               @on-enter="getList"
               class="m-r-5"></Input>
        <Select v-model="status"
                placeholder="是否绑定"
                @on-change="getList">
          <Option v-for="item in statusArr"
                  :value="item.id"
                  :key="item.id">{{ item.name }}</Option>
        </Select>
      </div>
      <Table ref="table"
             :columns="columns"
             :data="data"
             height="320"
             border
             stripe
             :loading="tableLoading"
             @on-row-click="onClickBind">
      </Table>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'equipmentBindingModal',
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '绑定对象'
    },
    item: null
  },
  data () {
    return {
      type: '',
      model: '',
      modelArr: [],
      number: '',
      status: 1,
      statusArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '未绑定'
        },
        {
          id: 2,
          name: '已绑定'
        },
      ],
      columns: [
        {
          title: '设备编号',
          key: 'equipcode',
          tooltip: true,
          align: 'center'
        },
        {
          title: '设备类型',
          key: 'equiptypename',
          tooltip: true,
          align: 'center'
        },
        {
          title: '绑定状态',
          key: 'bindstatus',
          tooltip: true,
          align: 'center'
        },
        {
          title: '型号-厂商',
          key: 'manufacturerid',
          tooltip: true,
          align: 'center'
        },
      ],
      data: [],
      tableLoading: false,
      // 绑定设备
      bindConfig: {
        title: '是否确认绑定',
        content: '正确绑定后，该设备将上传数据！'
      },
      // 解绑设备
      unbindConfig: {
        title: '是否确认此操作',
        content: '解绑后，该对象不再发送此类数据'
      },
      // 绑定并替换
      bindAndReplaceConfig: {
        title: '是否确认绑定',
        content: '解绑该设备原有绑定的设施，并把设备绑定在新设施上！'
      },
    }
  },
  watch: {
    visible (newVal) {
      newVal && this.getList()
    }
  },
  computed: {
    typeArr () {
      let arr = [{
        id: 0,
        name: '全部'
      }]
      return arr.concat(this.$store.getters.getEquipmentTypeAllList)
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateEquipmentTypeAllList',
    ]),
    init () {
      this.updateEquipmentTypeAllList()
    },
    onChangeType (value) {
      let arr = [{
        id: 0,
        name: '全部'
      }]
      this.$http.getEquipmentModelAllList({ equiptypeid: value }).then(res => {
        if (res.code === 200) {
          this.modelArr = arr.concat(res.result)
        }
      })
      this.model = ''
      this.getList()
    },
    getList () {
      let params = {
        equiptypeid: '',
        equipmodelid: '',
        bindstatus: '',
        equipcode: this.number
      }
      if (this.type) params.equiptypeid = this.type
      if (this.model) params.equipmodelid = this.model
      if (this.status) params.bindstatus = this.status === 1 ? 0 : 1
      this.tableLoading = true
      this.$http.getEquipmentAllList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.data = res.result
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 点击绑定
    onClickBind (row) {
      this.onBindEquipment(row, 1)
    },
    // 点击解绑
    onClickUnbind (item) {
      this.onBindEquipment(item, 0)
    },
    // 绑定设备
    onBindEquipment (obj, status) {
      let config
      if (status === 1) {
        config = obj.bindstatus === '未绑定' ? this.bindConfig : this.bindAndReplaceConfig
      } else {
        config = this.unbindConfig
      }
      this.$Modal.confirm({
        ...config,
        onOk: () => {
          let params
          if (status === 1) {
            params = {
              data: {
                status: status,
                equipid: obj.id,
                equiptype: obj.equiptypeid,
                equipcode: obj.equipcode,
                equiptypename: obj.equiptypename + '(' + obj.equipcode + ')',
                itemid: this.item.id,
                itemname: this.item.name,
                type: this.item.type,
                typeitem: this.item.item,
                itemdept: this.item.empldeptid,
              }
            }
          } else {
            params = {
              data: {
                status: status,
                equipid: obj.equipid,
                equiptype: obj.equiptype,
                equipcode: obj.equipcode,
                equiptypename: obj.equiptypename,
                itemid: '',
                itemname: '',
                type: '',
                typeitem: '',
                itemdept: '',
              }
            }
          }
          this.$http.equipmentBind(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              if (status === 1) {
                let boo = this.item.binds.some(items => items.equipid === params.data.equipid)
                console.log(boo);
                !boo && this.item.binds.push({
                  equipcode: params.data.equipcode,
                  equipid: params.data.equipid,
                  equiptype: params.data.equiptype,
                  equiptypename: params.data.equiptypename
                })
              } else {
                let index = this.item.binds.findIndex(items => items.equipid === params.data.equipid)
                this.item.binds.splice(index, 1)
              }
              this.getList()
              this.$emit('onClickConfirm')
            }
          })
        }
      })
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:item', null)
      }
    },
    resetData () {
      this.type = ''
      this.model = ''
      this.modelArr = []
      this.number = ''
      this.status = 1
      this.data = []
    }
  }
}
</script>

<style lang="scss" scoped>
.equipment {
  .equipment-cont {
    background: #f2f2f2;
    padding: 10px;
    margin: 10px 0;
    // height: 44px;
    overflow-y: scroll;
    font-size: 12px;
    display: flex;
    align-items: center;
    li {
      width: 140px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      line-height: 34px;
      font-size: 12px;
      box-sizing: border-box;
      padding: 5px;
      color: #353639;
      span {
        display: inline-block;
        width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    li:first-child {
      width: 96px;
    }
    .binding {
      padding: 4px !important;
      background-color: #fff;
      border: 1px solid #dddddd;
      margin: 11px 5px 11px 0;
      height: 22px;
      .binding-icon {
        cursor: pointer;
      }
    }
  }
  .equipment-filtrate {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
  }
}
</style>
