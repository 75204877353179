<template>
  <Modal :value="visible"
         :title="title"
         width="800"
         @on-visible-change="onChangeVisible"
         footer-hide
         class-name="vertical-center-modal">
    <!-- 设施的报警参数列表 -->
    <template v-if="thresholdType==='facl'">
      <Table ref="table"
             :columns="columnsFaclList"
             :data="data"
             height="400"
             border
             stripe
             :loading="tableLoading">
        <template slot-scope="{ row }"
                  slot="operation">
          <Button type="text"
                  class="tableOperaBtn"
                  @click.stop="onClickEditAlarm(row)">修改</Button>
          <Button type="text"
                  class="tableOperaBtn"
                  @click.stop="onClickStatusAlarm(row)"
                  v-if="dataId.split(',').length == 1">{{row.status==1?'禁用':'恢复'}}</Button>
          <!-- 批量设置同时显示 启用禁用 -->
          <Button type="text"
                  class="tableOperaBtn"
                  @click.stop="onBatchStatusAlarm(row,1)"
                  v-if="dataId.split(',').length > 1">启用</Button>
          <Button type="text"
                  class="tableOperaBtn"
                  @click.stop="onBatchStatusAlarm(row,0)"
                  v-if="dataId.split(',').length > 1">禁用</Button>
        </template>
      </Table>
    </template>
    <!-- 车辆、人员的报警参数列表 -->
    <template v-else>
      <Table ref="table"
             :columns="columnsCarOrPersonList"
             :data="data"
             height="400"
             border
             stripe
             :loading="tableLoading">
        <template slot-scope="{ row }"
                  slot="operation">
          <Button type="text"
                  class="tableOperaBtn"
                  @click.stop="onClickEditAlarm(row)">修改</Button>
          <Button type="text"
                  class="tableOperaBtn"
                  @click.stop="onClickStatusAlarm(row)"
                  v-if="dataId.split(',').length == 1">{{row.status==1?'禁用':'恢复'}}</Button>
          <!-- 批量设置同时显示 启用禁用 -->
          <Button type="text"
                  class="tableOperaBtn"
                  @click.stop="onBatchStatusAlarm(row,1)"
                  v-if="dataId.split(',').length > 1">启用</Button>
          <Button type="text"
                  class="tableOperaBtn"
                  @click.stop="onBatchStatusAlarm(row,0)"
                  v-if="dataId.split(',').length > 1">禁用</Button>
        </template>
      </Table>
    </template>
    <div class="tip"
         v-if="dataId.split(',').length > 1">
      批量操作的信息间阈值与启用状态不同时将不显示，若需修改为相同内容可点击“修改”进行修改
    </div>
    <!-- 修改按钮 -->
    <!-- 批量修改需要personIds -->
    <ParameterModal v-model="parameterDetailVisible"
                    :type="parameterDetailType"
                    :dataId.sync="parameterDetailDataId"
                    :personIds.sync="parameterDetailPersonIds"
                    :thresholdType="thresholdType"
                    :item="parameterDetailPersonItem"
                    @onClickConfirm="getList"></ParameterModal>
  </Modal>
</template>

<script>
import ParameterModal from '@/components/product/admin/modal/archives/ParameterModal'
export default {
  components: {
    ParameterModal
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '参数设置'
    },
    dataId: String,
    thresholdType: String,
    faclBigType: String
  },
  data () {
    return {
      data: [],
      parametDetailerType: '',
      columnsFaclList: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '系统名称',
          key: 'sysname',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '报警名称',
          key: 'alarmname',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '设施大类',
          key: 'faclitypename',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '阈值',
          key: 'threshold',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          minWidth: 60,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == 1) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '操作',
          slot: 'operation',
          width: 200,
          align: 'center'
        }
      ],
      columnsCarOrPersonList: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '系统名称',
          key: 'sysname',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '报警名称',
          key: 'alarmname',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '阈值',
          key: 'threshold',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          minWidth: 60,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == 1) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '操作',
          slot: 'operation',
          width: 200,
          align: 'center'
        }
      ],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableLoading: false,
      // 修改参数弹框
      parameterDetailVisible: false,
      parameterDetailDataId: '',
      parameterDetailPersonIds: '',
      parameterDetailPersonItem: {},
      parameterDetailType: '',
      parameterDetailTitle: ''
    }
  },
  watch: {
    dataId (newValue) {
      newValue && this.getList()
    }
  },
  methods: {
    getList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize
      }
      // 批量修改
      if (this.dataId.split(',').length > 1) {
        if (this.faclBigType) {
          params.faclitypeid = this.faclBigType
        }
        // params.thresholdtype = this.thresholdType
        params.ids = this.dataId
      } else {
        // 表格单个修改
        params.itemid = this.dataId
      }
      this.tableLoading = true
      this.$http.getAlarmList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.thresholdlist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 报警参数修改
    onClickEditAlarm (row) {
      this.parameterDetailVisible = true
      this.parameterDetailType = row.sysname
      this.parameterDetailDataId = row.id
      if (this.dataId.split(',').length > 1) {
        this.parameterDetailPersonIds = this.dataId
        this.parameterDetailPersonItem = row
      }
    },
    // 修改报警参数状态
    onClickStatusAlarm (row) {
      if (row.status == 1) {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 0
            }
            this.$store.dispatch('editTableAlarmStatus', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
          }
        })
      } else {
        this.$Modal.confirm({
          title: '恢复',
          content: '确认恢复?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 1
            }
            this.$store.dispatch('editTableAlarmStatus', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
          }
        })
      }
    },
    // 批量禁用启用
    onBatchStatusAlarm (row, status) {
      let text
      if (status == 0) {
        text = '禁用'
      } else {
        text = '启用'
      }
      this.$Modal.confirm({
        title: text,
        content: `确认${text}?`,
        onOk: () => {
          let params = {
            name: row.alarmname,
            sysname: row.sysname,
            status: status,
            personIds: this.dataId,
          }
          this.$store.dispatch('editTableAlarmStatus', params).then(res => {
            this.getList()
          }).catch(err => {
            this.$Message.info(err.message)
          })
        }
      })
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('update:dataId', '')
        this.$emit('update:faclBigType', '')
        this.$emit('onChange', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tip {
  margin-top: 10px;
}
</style>
