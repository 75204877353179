<template>
  <Modal :value="visible"
         :title="title"
         width="600"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <!-- 进出区域 -->
      <ul class="form-ul"
          v-if="type == '出区域' || type == '进区域'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>时长：</p>
          <Input v-model="threshold.sc"
                 placeholder="时长"
                 maxlength="30"
                 v-intLimit
                 class="input-right-5"></Input>
          <span class="li-unit">分钟</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>偏移量：</p>
          <Input v-model="threshold.pyl"
                 placeholder="偏移量"
                 maxlength="30"
                 v-floatLimit
                 class="input-right-5"></Input>
          <span class="li-unit">米</span>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>

      <!-- 定位失败、超时未清、超时报警、 -->
      <ul class="form-ul"
          v-if="type == '定位失败' || type == '超时未清' || type == '超时报警'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>时长：</p>
          <Input v-model="threshold.sc"
                 placeholder="时长"
                 maxlength="30"
                 v-intLimit
                 class="input-right-5"></Input>
          <span class="li-unit">分钟</span>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>

      <!-- 驾驶超时 -->
      <ul class="form-ul"
          v-if="type == '驾驶超时'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>最长驾驶：</p>
          <Input v-model="threshold.zcjs"
                 placeholder="最长驾驶"
                 maxlength="30"
                 v-intLimit
                 class="input-right-5"></Input>
          <span class="li-unit">分钟</span>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>

      <!-- 电源掉电、电源欠压 -->
      <ul class="form-ul"
          v-if="type == '电源掉电' || type == '电源欠压'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 v-stringLimit
                 maxlength="30"></Input>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>

      <!-- 漏油 -->
      <ul class="form-ul"
          v-if="type == '漏油'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 v-stringLimit
                 maxlength="30"></Input>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>油箱容错率：</p>
          <Input v-model="threshold.yxrcl"
                 placeholder="油箱容错率"
                 maxlength="30"
                 v-floatLimit
                 class="input-right-5"></Input>
          <span class="li-unit">%</span>
        </li> -->
        <!-- <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>油耗差：</p>
          <Input v-model="threshold.yxrcl"
                 placeholder="油耗差"
                 maxlength="30"
                 v-intLimit
                 class="input-right-5"></Input>
          <span class="li-unit">%</span>
        </li> -->
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>时长：</p>
          <Input v-model="timeinterval"
                 placeholder="时长"
                 maxlength="30"
                 v-intLimit
                 class="input-right-5"></Input>
          <span class="li-unit">min</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>耗油量超：</p>
          <Input v-model="threshold.yhxe"
                 placeholder="耗油量超"
                 maxlength="30"
                 v-intLimit
                 class="input-right-5"></Input>
          <span class="li-unit">L</span>
        </li>
        <!-- <li class="form-ul-li half"></li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>基数天数：</p>
          <Select v-model="basedays"
                  placeholder="基数天数">
            <Option v-for="item in cityList"
                    :value="item.id"
                    :key="item.id">{{ item.name }}</Option>
          </Select>
        </li>
        <li class="form-ul-li">
          <p class="tip">计算近多少天的，总耗油数/总行驶里程</p>
        </li> -->
        <!-- <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>

      <!-- 离线 -->
      <ul class="form-ul"
          v-if="type == '离线'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 v-stringLimit
                 maxlength="30"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>离线：</p>
          <Input v-model="threshold.lx"
                 placeholder="离线"
                 maxlength="30"
                 v-intLimit
                 class="input-right-5"></Input>
          <span class="li-unit">分钟</span>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>

      <!-- 疲劳驾驶 -->
      <ul class="form-ul"
          v-if="type == '疲劳驾驶'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 v-stringLimit
                 maxlength="30"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>休息：</p>
          <Input v-model="threshold.xx"
                 placeholder="休息"
                 maxlength="30"
                 v-intLimit
                 class="input-right-5"></Input>
          <span class="li-unit">分钟</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>驾驶：</p>
          <Input v-model="threshold.js"
                 placeholder="驾驶"
                 maxlength="30"
                 v-intLimit
                 class="input-right-5"></Input>
          <span class="li-unit">分钟</span>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>

      <!-- 超速 -->
      <ul class="form-ul"
          v-if="type == '超速'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li> -->
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>聚合时间：</p>
          <Input v-model="polymerizationtime"
                 placeholder="聚合时间"
                 maxlength="30"
                 v-floatLimit
                 class="input-right-5"></Input>
          <span class="li-unit">min</span>
        </li>
        <li class="form-ul-li">
          <p class="tip">聚合时间为间隔只要未超过聚合时间，则合并为一个违规，且时间叠加</p>
        </li>
        <li class="form-ul-title">
          <p>模式一：</p>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>限速：</p>
          <Input v-model="threshold.m_xs"
                 placeholder="限速"
                 maxlength="30"
                 v-floatLimit
                 class="input-right-5"></Input>
          <span class="li-unit">km/h</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>持续时长：</p>
          <Input v-model="threshold.m_sj"
                 placeholder="持续时长"
                 maxlength="30"
                 v-floatLimit
                 class="input-right-5"></Input>
          <span class="li-unit">分钟</span>
        </li>
        <li class="form-ul-title">
          <p>模式二：</p>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>限速：</p>
          <Input v-model="threshold.xs"
                 placeholder="限速"
                 maxlength="30"
                 v-floatLimit
                 class="input-right-5"></Input>
          <span class="li-unit">km/h</span>
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>

      <!-- 设备低电、SOS求救 -->
      <ul class="form-ul"
          v-if="type == '设备低电' || type == 'SOS求救'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 v-stringLimit
                 maxlength="30"></Input>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
      <!-- 离线 -->
      <!-- <ul class="form-ul"
          v-if="type == '离线'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>离线：</p>
          <Input v-model="threshold.lx"
                 placeholder="离线"
                 maxlength="30"
                 v-intLimit
                 class="input-right-5"></Input>
          <span class="li-unit">分钟</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul> -->

      <!-- 电耗报警 -->
      <ul class="form-ul"
          v-if="type == '电耗报警'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>用电量：</p>
          <Input v-model="threshold.ydl"
                 placeholder="用电量"
                 maxlength="30"
                 v-floatLimit
                 class="input-right-5"></Input>
          <span class="li-unit">km/h</span>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
      <!-- 水耗报警 -->
      <ul class="form-ul"
          v-if="type == '水耗报警'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>用水量：</p>
          <Input v-model="threshold.ysl"
                 placeholder="用水量"
                 maxlength="30"
                 v-floatLimit
                 class="input-right-5"></Input>
          <span class="li-unit">吨</span>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
      <!-- 烟雾报警 -->
      <ul class="form-ul"
          v-if="type == '烟雾报警'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 v-stringLimit
                 maxlength="30"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>浓度：</p>
          <Input v-model="threshold.ywnd"
                 placeholder="浓度"
                 class="input-right-5"
                 maxlength="30"
                 v-floatLimit></Input>
          <span class="li-unit">ppm</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>聚合时间：</p>
          <Input v-model="polymerizationtime"
                 placeholder="聚合时间"
                 maxlength="30"
                 v-floatLimit
                 class="input-right-5"></Input>
          <span class="li-unit">min</span>
        </li>
        <li class="form-ul-li">
          <p class="tip">聚合时间为间隔只要未超过聚合时间，则合并为一个违规，且时间叠加</p>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
      <!-- PM10 -->
      <ul class="form-ul"
          v-if="type == 'PM10'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>限值：</p>
          <Input v-model="threshold.pmxz"
                 placeholder="限值"
                 maxlength="30"
                 v-floatLimit></Input>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
      <!-- PM2.5 -->
      <ul class="form-ul"
          v-if="type == 'PM2.5'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>限值：</p>
          <Input v-model="threshold.pmxz"
                 placeholder="限值"
                 maxlength="30"
                 v-floatLimit></Input>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>

      <!-- 倾斜报警 -->
      <ul class="form-ul"
          v-if="type == '倾斜报警'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>角度：</p>
          <Input v-model="threshold.qxjd"
                 placeholder="角度"
                 maxlength="30"
                 v-floatLimit
                 class="input-right-5"></Input>
          <span class="li-unit">°</span>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
      <!-- 温度报警 -->
      <ul class="form-ul"
          v-if="type == '温度报警'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>温度：</p>
          <Input v-model="threshold.wd"
                 placeholder="温度"
                 maxlength="30"
                 v-floatLimit
                 class="input-right-5"></Input>
          <span class="li-unit">°C</span>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
      <!-- 湿度报警 -->
      <ul class="form-ul"
          v-if="type == '湿度报警'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 v-stringLimit
                 maxlength="30"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>湿度：</p>
          <Input v-model="threshold.sd"
                 placeholder="湿度"
                 maxlength="30"
                 v-floatLimit></Input>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
      <!-- 其他报警 -->
      <ul class="form-ul"
          v-if="type == '其他报警'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>xx：</p>
          <Input v-model="threshold.xx"
                 placeholder=""
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
      <!-- 满溢报警 -->
      <ul class="form-ul"
          v-if="type == '满溢报警'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>高度：</p>
          <Input v-model="threshold.gd"
                 placeholder="高度"
                 class="input-right-5"
                 maxlength="30"
                 v-floatLimit></Input>
          <span class="li-unit">cm</span>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
      <!-- 氨气报警 -->
      <ul class="form-ul"
          v-if="type == '氨气报警'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>浓度：</p>
          <Input v-model="threshold.aqnd"
                 placeholder="浓度"
                 maxlength="30"
                 v-floatLimit
                 class="input-right-5"></Input>
          <span class="li-unit">ppm</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>聚合时间：</p>
          <Input v-model="polymerizationtime"
                 placeholder="聚合时间"
                 maxlength="30"
                 v-floatLimit
                 class="input-right-5"></Input>
          <span class="li-unit">min</span>
        </li>
        <li class="form-ul-li">
          <p class="tip">聚合时间为间隔只要未超过聚合时间，则合并为一个违规，且时间叠加</p>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
      <!-- 硫化氢报警 -->
      <ul class="form-ul"
          v-if="type == '硫化氢报警'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 v-stringLimit
                 maxlength="30"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>浓度：</p>
          <Input v-model="threshold.lhqnd"
                 placeholder="浓度"
                 maxlength="30"
                 v-floatLimit
                 class="input-right-5"></Input>
          <span class="li-unit">ppm</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>聚合时间：</p>
          <Input v-model="polymerizationtime"
                 placeholder="聚合时间"
                 maxlength="30"
                 v-floatLimit
                 class="input-right-5"></Input>
          <span class="li-unit">min</span>
        </li>
        <li class="form-ul-li">
          <p class="tip">聚合时间为间隔只要未超过聚合时间，则合并为一个违规，且时间叠加</p>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
      <!-- 开盖报警 -->
      <ul class="form-ul"
          v-if="type == '开盖报警'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 v-stringLimit
                 maxlength="30"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>时长：</p>
          <Input v-model="manholeCoverLidInput"
                 placeholder="时长"
                 maxlength="30"
                 v-floatLimit
                 class="input-right-5"></Input>
          <span class="li-unit">
            <Select v-model="manholeCoverLid"
                    placeholder=""
                    style="width:50px">
              <Option v-for="item in manholeCoverLidArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
          </span>
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
      <!-- 水位超限 -->
      <ul class="form-ul"
          v-if="type == '水位超限'">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>报警名称：</p>
          <Input v-model="name"
                 placeholder="报警名称"
                 v-stringLimit
                 maxlength="30"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>时长：</p>
          <Input v-model="manholeCoverWaterInput"
                 placeholder="时长"
                 maxlength="30"
                 v-floatLimit
                 class="input-right-5"></Input>
          <span class="li-unit">
            <Select v-model="manholeCoverWater"
                    placeholder=""
                    style="width:50px">
              <Option v-for="item in manholeCoverWaterArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
          </span>
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    thresholdType: String,
    type: String,
    dataId: String,
    personIds: String,
    item: Object
  },
  data () {
    return {
      loading: false,
      // title: '',
      sysname: '',
      name: '',
      threshold: {},
      status: true,
      remark: '',
      // 漏油 检测时间间隔
      timeinterval: '',
      // 漏油 基准天数
      basedays: '',
      // 超速 烟雾报警 硫化氢报警 氨气报警  聚合时间
      polymerizationtime: '',
      cityList: [
        {
          id: 1,
          name: '近1天'
        },
        {
          id: 3,
          name: '近3天'
        },
        {
          id: 7,
          name: '近7天'
        },
        {
          id: 15,
          name: '近15天'
        },
        {
          id: 30,
          name: '近30天'
        },
      ],
      manholeCoverLidInput: '',
      manholeCoverLid: '',
      manholeCoverLidArr: [
        {
          id: 1,
          name: '秒'
        }, {
          id: 2,
          name: '分'
        },
      ],
      manholeCoverWaterInput: '',
      manholeCoverWater: '',
      manholeCoverWaterArr: [
        {
          id: 1,
          name: '秒'
        }, {
          id: 2,
          name: '分'
        },
      ],
    }
  },
  watch: {
    dataId (newValue) {
      if (newValue) {
        if (this.personIds) {
          this.getBatchDetail()
        } else {
          this.getDetail()
        }
      }
    }
  },
  computed: {
    title () {
      let str = ''
      if (this.thresholdType == 'car') {
        str = '修改车辆参数'
      } else if (this.thresholdType == 'person') {
        str = '修改人员参数'
      } else if (this.thresholdType == 'facl') {
        str = '修改设施参数'
      } else if (this.thresholdType == 'tree') {
        str = '修改园林参数'
      }
      return str
    }
  },
  methods: {
    getDetail () {
      // console.log(this.item);
      let params = {
        id: this.dataId
      }
      this.$store.dispatch('getAlarmDetail', params).then(res => {
        // this.sysname = res.sysname
        // this.name = res.name
        // this.remark = res.remark
        // 因为ids返回了空，id又映射为了personIds，personIds是传进来的，不删除会报错
        delete res.personIds
        for (const i in res) {
          this[i] = res[i]
        }
        this.status = res.status == 1 ? true : false
        this.threshold = JSON.parse(res.threshold)
        if (res.sysname == '开盖报警') {
          if (this.threshold.ms) {
            this.manholeCoverLid = 1
            this.manholeCoverLidInput = this.threshold.ms
          } else {
            this.manholeCoverLid = 2
            this.manholeCoverLidInput = this.threshold.sc
          }
        }
        if (res.sysname == '水位超限') {
          if (this.threshold.ms) {
            this.manholeCoverWater = 1
            this.manholeCoverWaterInput = this.threshold.ms
          } else {
            this.manholeCoverWater = 2
            this.manholeCoverWaterInput = this.threshold.sc
          }
        }
      })
    },
    getBatchDetail () {
      console.log(this.item);
      let params = {
        sysname: this.item.sysname,
        name: this.item.alarmname,
        personIds: this.personIds
      }
      this.$store.dispatch('getAlarmDetail', params).then(res => {
        // 因为ids返回了空，id又映射为了personIds，personIds是传进来的，不删除会报错
        delete res.personIds
        for (const i in res) {
          this[i] = res[i]
        }
        this.status = res.status == 1 ? true : false
        this.threshold = JSON.parse(res.threshold)
      })
    },
    onClickConfirm () {
      if (!this.name) return this.$Message.info('请输入报警名称');
      if (this.type == '出区域' || this.type == '进区域' || this.type == '定位失败' || this.type == '超时未清' || this.type == '超时报警') {
        if (!this.threshold.sc) return this.$Message.info('请输入时长');
      }
      if (this.type == '出区域' || this.type == '进区域') {
        if (!this.threshold.pyl) return this.$Message.info('请输入偏移量');
      }
      if (this.type == '驾驶超时') {
        if (!this.threshold.zcjs) return this.$Message.info('请输入最长驾驶');
      }
      if (this.type == '漏油') {
        // if (!this.threshold.yxrcl) return this.$Message.info('请输入油箱容错率');
        // if (!this.threshold.yxrcl) return this.$Message.info('请输入油耗差');
        if (!this.timeinterval) return this.$Message.info('请输入时长');
        if (!this.threshold.yhxe) return this.$Message.info('请输入油耗限额');
        // if (!this.basedays) return this.$Message.info('请选择基准天数');
      }
      if (this.type == '离线') {
        if (!this.threshold.lx) return this.$Message.info('请输入离线时长');
      }
      if (this.type == '疲劳驾驶') {
        if (!this.threshold.xx) return this.$Message.info('请输入休息时长');
        if (!this.threshold.js) return this.$Message.info('请输入驾驶时长');
      }
      if (this.type == '超速') {
        if (!this.threshold.xs) return this.$Message.info('请输入限速');
        if (!this.polymerizationtime) return this.$Message.info('请输入聚合时间');
      }
      if (this.type == '电耗报警') {
        if (!this.threshold.ydl) return this.$Message.info('请输入用电量');
      }
      if (this.type == '水耗报警') {
        if (!this.threshold.ysl) return this.$Message.info('请输入用水量');
      }
      if (this.type == '烟雾报警') {
        if (!this.threshold.ywnd) return this.$Message.info('请输入浓度');
        if (!this.polymerizationtime) return this.$Message.info('请输入聚合时间');
      }
      if (this.type == '硫化氢报警') {
        if (!this.threshold.lhqnd) return this.$Message.info('请输入浓度');
        if (!this.polymerizationtime) return this.$Message.info('请输入聚合时间');
      }
      if (this.type == '氨气报警') {
        if (!this.threshold.aqnd) return this.$Message.info('请输入浓度');
        if (!this.polymerizationtime) return this.$Message.info('请输入聚合时间');
      }
      if (this.type == 'PM10' || this.type == 'PM2.5') {
        if (!this.threshold.pmxz) return this.$Message.info('请输入限值');
      }
      if (this.type == '倾斜报警') {
        if (!this.threshold.qxjd) return this.$Message.info('请输入角度');
      }
      if (this.type == '温度报警') {
        if (!this.threshold.wd) return this.$Message.info('请输入温度');
      }
      if (this.type == '湿度报警') {
        if (!this.threshold.sd) return this.$Message.info('请输入湿度');
      }
      if (this.type == '满溢报警') {
        if (!this.threshold.gd) return this.$Message.info('请输入高度');
      }
      if (this.type == '离线') {
        if (!this.threshold.lx) return this.$Message.info('请输入离线时长');
      }
      if (this.type == '开盖报警') {
        if (!this.manholeCoverLidInput) return this.$Message.info('请输入时长');
      }
      if (this.type == '水位超限') {
        if (!this.manholeCoverWaterInput) return this.$Message.info('请输入时长');
      }
      this.loading = true
      let threshold
      if (this.sysname == '开盖报警') {
        if (this.manholeCoverLid == 1) {
          threshold = { ms: this.manholeCoverLidInput }
        } else {
          threshold = { sc: this.manholeCoverLidInput }
        }
      } else if (this.sysname == '水位超限') {
        if (this.manholeCoverWater == 1) {
          threshold = { ms: this.manholeCoverWaterInput }
        } else {
          threshold = { sc: this.manholeCoverWaterInput }
        }
      } else {
        threshold = this.threshold
      }
      let params = {
        sysname: this.sysname,
        name: this.name,
        threshold: JSON.stringify(threshold),
        remark: this.remark
      }
      // 2021年9月11日 车辆弹框去掉启用状态
      // 2021年10月9日 弹窗全部去掉启用状态
      // if (this.thresholdType != 'car') {
      //   params.status = this.status ? 1 : 0
      // }
      if (this.type == '漏油') {
        params.timeinterval = this.timeinterval
        // params.basedays = this.basedays
      }
      if (this.type == '超速' || this.type == '烟雾报警' || this.type == '硫化氢报警' || this.type == '氨气报警') {
        params.polymerizationtime = this.polymerizationtime
      }
      if (this.dataId) params.id = this.dataId
      // 批量修改 人的ids
      if (this.personIds) params.personIds = this.personIds
      this.$store.dispatch('createOrEditAlarm', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.sysname = ''
        this.name = ''
        this.thresholdtype = ''
        this.threshold = {}
        this.status = true
        this.remark = ''
        this.basedays = ''
        this.timeinterval = ''
        this.polymerizationtime = ''
        this.manholeCoverWaterInput = ''
        this.manholeCoverLidInput = ''
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
        if (this.personIds) {
          this.$emit('update:personIds', '')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;

  span:first-child {
    font-weight: 700;
    font-size: 16px;
    padding-left: 10px;
    border-left: 2px solid #25bb96;
  }
}
</style>
